<template>
  <v-container
    :class="{
      'title-item-dark': $vuetify.theme.dark,
    }"
    fluid
  >
    
    <v-card-actions>
      <v-row class="mt-1">
        <v-col>
          <btn-voltar :to="`/areas/${$route.params.idArea}/convenio`" />
        </v-col>
      </v-row>
    </v-card-actions>

    <convenio-campos
      :carregando-salvar="carregandoSalvar"
      :carregando-editar-nome="carregandoEditarNome"
      :validacao-formulario="validacaoFormulario"
      :convenio="convenio"
      @salvar="salvar"
    />
  </v-container>
</template>

<script>
import convenios from "@/api/convenios";

export default {
  components: {
    ConvenioCampos: () => import("./ConvenioCampos"),
  },

  data() {
    return {
      carregandoSalvar: false,
     
      carregandoEditarNome: false,
      validacaoFormulario: {},
      convenio: {},
    };
  },

  mounted() {
    this.buscar();
  },

  methods: {
    async buscar() {
      try {
        this.$store.commit(`layout/SET_LOADING`, true);
        const resposta = await convenios.buscar(
          this.$route.params.id
        );

        var convenio = {
          descricao: resposta.data.data.descricao,
          ativo: resposta.data.data.ativo,
        };

        this.convenio = convenio;
      } catch (e) {
        this.$router.push("/convenio", () =>
          this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) })
        );
      } finally {
        this.$store.commit(`layout/SET_LOADING`, false);
      }
    },

    async salvar(convenio) {
      this.carregandoSalvar = true;

      try {
        await convenios.atualizar(
          this.$route.params.id,
          convenio
        );
        this.$router.push(`/areas/${this.$route.params.idArea}/convenio`, () =>
          this.$snackbar.mostrar({
            cor: "success",
            mensagem: this.$mensagens._("sucesso_editar"),
          })
        );
      } catch (e) {
        if (e.response.status === 422) {
          this.validacaoFormulario = e.response.data.erros;
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
        return;
      } finally {
        this.carregandoSalvar = false;
      }
    },
  },
};
</script>
